import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


function App() {

  const [ sum, setSum ] = useState(0);
  const [ months, setMonths ] = useState(240);
  const [ contribution, setContribution ] = useState(50);
  const [ rate, setRate ] = useState(1);

  let result = [];

  // useEffect(() => {
    // let result = [];
  let currentSum = sum;
  let summaryContributions = months * contribution;
  let increase = 0;
  const rateFloat = rate / 100;
  for (let i = 1; i <= months; i++) {
    currentSum += contribution;
    increase = currentSum * rateFloat * 0.7;
    currentSum += increase;
    result.push({
      sum: currentSum,
      increase: increase
    });
    console.log(currentSum);
    }
  // }, [ sum, months, contribution, rate ]);

  return (
    <div className="App">
    <div style={{position: 'sticky', top: '0', background: 'white', boxShadow: '0 0 5px grey', padding: '10px'}}>
      <div>Start sum <input className="form-control" type="number" onChange={(e) => setSum(Number(e.target.value))} defaultValue={sum} /></div>
      <div>Months <input className="form-control" type="number" onChange={(e) => setMonths(Number(e.target.value))} defaultValue={months} /></div>
      <div>Contribution monthly <input className="form-control" type="number" onChange={(e) => setContribution(Number(e.target.value))} defaultValue={contribution} /></div>
      <div>Rate percentage <input className="form-control" type="number" onChange={(e) => setRate(Number(e.target.value))} defaultValue={rate} /></div>

      <div className="text-muted p-2">Considering 30% USA stock dividends tax</div>
    </div>

    <div className="list">
      <div>Month</div>
      <div>Sum</div>
      <div>Increase</div>
    {
      result.map((row, index) => <React.Fragment>
        <div>{index+1}</div>
        <div>{row.sum.toFixed(2)}</div>
        <div>{row.increase.toFixed(2)}</div>
      </React.Fragment>)
    }
    </div>

    <div className="p-3">Summary contributions: {summaryContributions}</div>
    </div>
  );
}

export default App;
